<template>
  <div class="n-footer">
    <div class="container">
      <!-- logo -->
      <div class="f-logo">
        <!-- <img src="" alt="" /> -->
        <h2>同方鼎欣科技股份有限公司</h2>
      </div>

      <p>
        Tongfang Dingxin Technology Co., Ltd
      </p>
      <p>公司邮箱：zhipeng_li@asdc.com.cn<span style="margin:0 15px;">--</span>域名：caiminjia.com </p>

      <p>公司地址：北京市海淀区得实大厦3F</p>
      <p><a href="https://beian.miit.gov.cn/#/Integrated/index">备案号：京ICP备17007721号-19</a></p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";

.n-footer {
  width: 100%;
  // height: 200px;
  background-color: #2d3237;

  .container {
    height: 100%;
    padding-top: 20px;
    padding-bottom: 10px;
    box-sizing: border-box;

    // logo
    .f-logo {
      width: 300px;
      height: 80px;
      margin: 0 auto;

      // img {
      //   width: 100%;
      //   height: 100%;
      // }
      h2 {
        color: #fff;
        text-align: center;
        line-height: 80px;
      }
    }

    p {
      font-size: 14px;
      color: #888;
      text-align: center;
      margin: 15px 0;

      a {
        color: #888;
      }
    }
  }
}
</style>