<template>
  <div class="ac2">
    <!-- banner -->
    <div class="banner">
      <img src="/imgs/b5.jpg" alt="" />
    </div>
    <!-- 顶宽布局 -->
    <div class="container">
      <!-- 标题 -->
      <div class="us-title">
        <p>关于我们</p>
        <span>About Us</span>
      </div>
      <!-- 容器 -->
      <div class="cont-flex">
        <!-- 右侧 介绍 -->
        <div class="us-info">
          <p>
            北京呈天网络科技股份有限公司成立于2008年08月07日，注册地位于北京市石景山区实兴大街30号院3号楼2层A-0037房间
          </p>
          <p>
            经营范围包括一般项目：软件开发；动漫游戏开发；数字文化创意软件开发；技术服务、技术开发、技术咨询、技术交流、技术转让、技术推广；网络技术服务；数字内容制作服务（不含出版发行）；数字技术服务；计算机系统服务；信息技术咨询服务；计算机软硬件及辅助设备零售；货物进出口；技术进出口；进出口代理；广告设计、代理；平面设计；文艺创作；广告制作；广告发布；
          </p>

          <p>
            数字创意产品展览展示服务；会议及展览服务。（除依法须经批准的项目外，凭营业执照依法自主开展经营活动）许可项目：网络文化经营；互联网信息服务；互联网游戏服务；第二类增值电信业务。（依法须经批准的项目，经相关部门批准后方可开展经营活动，具体经营项目以相关部门批准文件或许可证件为准）（不得从事国家和本市产业政策禁止和限制类项目的经营活动。）
          </p>

          <!-- <p>智美理护为创造价值而生，专注美好生活服务供应商。</p> -->
        </div>
        <div class="us-img">
          <img src="/imgs/info2.jpg" alt="" />
        </div>
      </div>
      <!-- 文本区 -->
      <div class="a2-info" style="display: none">
        <!-- 使命 -->
        <div class="a2-item">
          <div class="title"><span>我们的使命</span></div>
          <p>生者悦之 | 疾者舒之 | 老者安之</p>
        </div>
        <!-- 愿景 -->
        <div class="a2-item">
          <div class="title"><span>我们的愿景</span></div>
          <p>为亿万中国家庭提供有价值的照护服务&健康服务</p>
          <p>为千万照护服务&健康服务人才提供价值实现的服务</p>
        </div>
        <!-- 价值观 -->
        <div class="a2-item">
          <div class="title"><span>我们的价值观</span></div>
          <p>朴素、尊重、责任、同理、专注、专业、创新、极致</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "swiper/css/swiper.css";

export default {
  name: "ac1",
  components: {},
  data() {
    return {};
  },
  mounted() {
    document.title = "同方鼎欣科技股份有限公司";
  },
};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";

.ac2 {

  //   margin-top: 60px;
  .banner {
    width: 100%;
    // height: auto;
    height: 400px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .container {
    // height: 1000px;
    margin: 0 auto;
    padding-top: 30px;
    box-sizing: border-box;

    // 标题
    .us-title {
      width: 314px;
      border-bottom: 2px solid #bbbbbb;
      font-size: 24px;
      line-height: 30px;
      margin: 0 auto 60px;

      p {
        position: relative;
        display: inline-block;
        font-weight: 700;

        &::after {
          position: absolute;
          content: " ";
          width: 100%;
          bottom: -8px;
          left: 0;
          border-bottom: 5px solid #bbbbbb;
        }
      }

      span {
        font-size: 18px;
        margin-left: 10px;
      }
    }

    // 容器
    .cont-flex {
      display: flex;
      border: 1px solid #eeeeee;
    }

    .us-img {
      padding: 8px;

      img {
        width: 470px;
        height: 410px;
        // margin: 30px auto;
      }
    }

    // 右侧 信息
    .us-info {
      flex: 1;
      padding: 20px;
      text-indent: 24px;

      p {
        font-size: 14px;
        line-height: 50px;
        margin: 20px 0;
      }
    }

    // 文本区
    .a2-info {
      padding-top: 50px;

      .a2-item {
        .title {
          border-bottom: 1px solid #f0f0f0;
          margin-bottom: 20px;
          margin-top: 20px;

          span {
            background: #f0f0f0;
            display: inline-block;
            width: 182px;
            font-size: 18px;
            line-height: 40px;
            text-indent: 30px;
          }
        }

        p {
          font-size: 14px;
          line-height: 30px;
        }
      }
    }
  }
}
</style>
