<template>
  <div>
    <nav-header></nav-header>
    <router-view></router-view>
    <nav-footer></nav-footer>
  </div>
</template>

<script>
import NavFooter from "./../components/NavFooter";
import NavHeader from "./../components/NavHeader";
export default {
  name: "home",
  components: {
    NavFooter,
    NavHeader,
  },
  mounted() {
    document.title = "同方鼎欣科技股份有限公司";
  },
  data() {
    return {};
  },
};
</script>
<style lang="" scoped></style>
